'use strict';

function initializeAtSelects() {
    const select2Settings = {
        width: '100%',
        dropdownCssClass: 'select-dropdown always-top'
    }
    let selects = document.querySelectorAll('.at-select');

    function initializeTextarea(textarea, select2Element) {
        function handleKeyup(e) {
            let value = textarea.value;
            if (textarea === document.activeElement) {
                if (e.keyCode == 27 ) { // Escape
                    select2Element.select2('close');
                }
                else {
                    let lastValueChar = value.charAt(value.length - 1);
                    let secondToLastValueChar = value.charAt(value.length - 2);
    
                    // Only open select if a single @ is typed at the end of the textarea value,
                    // that is not part of something else, i.e. an email address
                    if ( lastValueChar == '@' && (secondToLastValueChar == ' ' || value.length == 1) ) {
                        select2Element.select2('open');
                    }
                }
            }
        }

        textarea.addEventListener('keyup', handleKeyup);
    }

    function initializeSelect(select) {
        let textarea = document.getElementById(select.dataset.targetId);

        if (textarea != undefined) {
            let select2Element = j(select).select2(select2Settings);

            function handleListClose() {
                textarea.focus();
            }

            function handleDropdownKeydown(e) {
                // Enter and escape should be handled by browser already
                if (e.keyCode == 13 || e.keyCode == 27) { // Enter || Escape
                    return;
                }

                // Since on keydown the triggering key has not ben addet to the value
                // space and backspace need to be handled separately
                if (e.keyCode == 32 ) { // Space
                    // Because textarea is focused, space is typed in there automatically                    
                    select2Element.select2('close');
                    textarea.focus();
                    return;
                }

                if (e.keyCode == 8 ) { // Backspace
                    let lastIndex = textarea.value.length - 1;
                    let lastChar = textarea.value.charAt(lastIndex);

                    // Close dropdown if the @ that triggered it to open is deleted
                    if (lastChar == '@') {
                        select2Element.select2('close');
                    }

                    textarea.value = textarea.value.slice(0, lastIndex);

                    return;
                }

                // Add character of triggering key to textarea value
                // Ignore all functional keys like 'alt'. Their e.key is more than one character long
                let atSections = textarea.value.split(' @');
                let currentChar = e.key.length == 1 ? e.key : ''; 

                if (atSections.length > 1) {
                    atSections[atSections.length - 1] = e.currentTarget.value + currentChar;
                    textarea.value = atSections.join(' @');
                }
                // If the first and only character in textarea is an @, this will trigger
                else {
                    textarea.value = textarea.value + currentChar;
                }
            }

            function handleOpen() {
                // Since Select2 adds and removes its dropdown on open or close respectively,
                // this event listener needs to be attached on open every time
                let dropdownSearch = document.querySelector('.select2-container .select2-search__field');
                
                if (dropdownSearch != undefined) {
                    // Keydown requires more handling, but allows for a smoother typing experience free of lag
                    dropdownSearch.addEventListener('keydown', handleDropdownKeydown);
                }
            }

            function handleSelection() {
                let values = textarea.value.split(' @');
                let newAtMention = select2Element.select2('data')[0].element.dataset.atValue;
                
                if (values.length > 1) {
                    values[values.length - 1] = newAtMention;
                    textarea.value = values.join(' @');
                }
                // If the first and only character in textarea is an @, this will trigger
                else {
                    textarea.value = '@' + newAtMention;
                }
                
                // Reset value, in case mentionable needs to be selected multiple times in succession
                select2Element.val(null).trigger('change');
            }

            select2Element.val(null).trigger('change'); // Remove default value of 1, or item 1 cannot be selected the first time
            select2Element.on('select2:open', () => handleOpen());
            select2Element.on('select2:select', () => handleSelection());

            // closing a select2 element sets focus to it.
            // setTimeout is required to reset focus back to textarea to continue typing
            select2Element.on('select2:close', () => setTimeout(handleListClose, 0))
            initializeTextarea(textarea, select2Element);
        }
        else {
            console.log('data-target-id for at-select could not be found');
        }
    }


    selects.forEach(select => initializeSelect(select));
}

window.addEventListener('DOMContentLoaded', initializeAtSelects);