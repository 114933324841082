'use strict';

const initializeSideMenu = () => {
    const sideMenuBar = document.getElementById('side-menu-bar');
    const navbar = document.getElementById('navbar');
    const sidebarMenuDesktop = document.getElementById("side-menu");
    const mask = document.getElementById("responsive-sidebar-mask");

    //Function for expanding a menu element if page is loaded
    const expandActiveMenuItem = () => {
        let menuAnchors;
        if (sideMenuBar.classList.contains('menu-minimized')) {
            menuAnchors = Array.from(document.querySelectorAll('#side-menu-bar .first-level > li:not(.menu-minimized-trigger) > div > a')); 
        } else {
            menuAnchors = Array.from(document.querySelectorAll('#side-menu-bar li:not(.menu-minimized-trigger) a'));   
        }
        const expandableItems = Array.from(document.querySelectorAll('.first-level > li'));
    
        // Look for a menu anchor that matches the current URL
        let currentAnchor = undefined;
        let currentURL = window.location.href;
        currentURL = currentURL.split('?')[0]; // Strip all eventual URL variables - required for pagination
    
        while(currentAnchor === undefined) {
            currentAnchor = menuAnchors.find(element => currentURL === element.href);
            currentURL = currentURL.split(new RegExp('/|#'));
            currentURL.pop();
    
            // if no match was found
            if(currentURL.length <= 1) {
                currentAnchor = '';
                return;
            }
            currentURL = currentURL.join('/');
        }
        currentAnchor.classList.add("active");
    
        //  Look for the menu entry containing the anchor
        let itemToExpand = expandableItems.find(element => element.contains(currentAnchor));
    
    
        if (itemToExpand != null && !sideMenuBar.classList.contains('menu-minimized')) {
            let currentExpander = itemToExpand.querySelector('.expander');
    
            if (currentExpander != null && !currentExpander.classList.contains('expanded')) {
                j(currentExpander).trigger('click');
            }
    
            // Check if the current anchor is from a first-level menu item to set its style to 'active'
            if (currentAnchor.classList.contains('is-first-level')) {
                (currentExpander) ? currentExpander.classList.add('active') : '';
            }
        }        
    }
    
    // Function for eventListeners
    const setupMenuEventListeners = () => {
        // Listener for closing Menu when background is clicked
        mask.addEventListener("click", closeSideMenu);
        function closeSideMenu() {
            j('#side-menu-bar').animate({
                left: -j('#side-menu-bar').outerWidth()
            }, 300, "swing", function(){
    
            });
            toggleMask("close");
        }
        
        // Listener for opening responsive Menu when menu button is clicked
        j('#responsive-sidebar-trigger').click(function(){
            j('#side-menu-bar').animate({
                left: 0
            }, 300, "swing", function(){
    
            });
            toggleMask("open");
        });
    
        // Listener for expanding a menu entry if clicked on +
        j(sidebarMenuDesktop).on("click", ".expander", toggleExpand);
        function toggleExpand(e) {
            let clickedExpander = e.currentTarget;
            let elementToExpand = clickedExpander.parentElement.parentElement; // Climb up in DOM hierarchy twice, up to li Element
    
            elementToExpand.classList.toggle('expanded');
            clickedExpander.classList.toggle('expanded');
    
            animateMenuExpand(elementToExpand.querySelector('.second-level'));
        }

        // Listener for minimizing/restoring menu in desktop mode
        j('.menu-minimized-trigger').click(function () {
            if (sideMenuBar.classList.contains('menu-minimized')) {
                sideMenuBar.classList.remove('menu-minimized');
                sideMenuBar.querySelector('.fas.fa-angle-double-right').classList.replace('fa-angle-double-right', 'fa-angle-double-left');
            } else {
                sideMenuBar.classList.add('menu-minimized');
                sideMenuBar.querySelector('.fas.fa-angle-double-left').classList.replace('fa-angle-double-left', 'fa-angle-double-right');
            }
            axios.post('/einstellungen/menu_minimieren');
            sideMenuBar.querySelector('.active').classList.remove('active');
            window.dispatchEvent(new Event('resize'));
            expandActiveMenuItem();
        });
    }

    // Function for showing/hiding opacity mask on responsive menu
    const toggleMask = (command) => {
        let duration = 300;
        
        switch (command) {
            case "open"     :   mask.style.display = "block"; 
                                j("#responsive-sidebar-mask").fadeTo(duration, 0.5);
                                break;
            case "close"    :  j("#responsive-sidebar-mask").fadeTo(duration, 0, function() { mask.style.display = "none"; });
                                break;
        }
    }
    
    // Function for animating second level menus on expand/retract
    const animateMenuExpand = (menuElement) => {
        if (menuElement.style.height === '0px' || menuElement.style.height === '') {
            let menuHeight = 0
            Array.from(menuElement.children).forEach(element => menuHeight += j(element).outerHeight());
    
            j(menuElement).animate({
                height: menuHeight + 'px'
            }, 300, "swing", function() {
                j(document.body).trigger("sticky_kit:recalc");
            });
        }
        else {
            j(menuElement).animate({
                height: 0
            }, 300, "swing", function() {
                j(document.body).trigger("sticky_kit:recalc");
            });
        }
    }

    const keepMenuBelowHeader = () => {
        if (window.matchMedia('(max-width: 1087px)').matches) {
            // !important required to override bulma padding styles on columns.is-gapless > column
            sideMenuBar.style.setProperty('padding-top', navbar.clientHeight + 'px', 'important');
        }
        else {
            sideMenuBar.style.paddingTop = '';
        }
    }

    // Initialization goes here
    if (document.querySelector('#side-menu-bar') != null) {
        setupMenuEventListeners();
        expandActiveMenuItem();

        // Sticky kit initialization
        let offset = document.querySelector('header').clientHeight;
        j('#side-menu-bar > aside').stick_in_parent({
            'offset_top': offset
        });


        // Dynamically set padding top on menu bar based on viewport width
        keepMenuBelowHeader(); // Position menu correctly when page loads
        window.addEventListener('resize', keepMenuBelowHeader)
    }
}

window.addEventListener('DOMContentLoaded', initializeSideMenu);