'use strict';

const initializeToggleAllCheckboxes = () => {
    let buttons = document.querySelectorAll('.toggle-all-checkboxes');

    const setupButton = (button) => {
        let target = document.getElementById(button.dataset.targetId);
        if (target == undefined) {
            return;
        }
        let checkboxes = Array.from(target.querySelectorAll('input[type=checkbox]'));

        const handleClick = (e) => {
            e.currentTarget.classList.toggle('is-active');
            checkboxes.forEach(checkbox => checkbox.checked = e.currentTarget.classList.contains('is-active'));
        };

        if (checkboxes.every(checkbox => checkbox.checked)) {
            button.classList.add('is-active');
        }
        button.addEventListener('click', handleClick);
    };

    buttons.forEach(button => setupButton(button));
}

window.addEventListener('DOMContentLoaded', initializeToggleAllCheckboxes);