'use strict';
const initializePopups = () => {
    const popups = document.querySelectorAll('.modal.popup');

    const relativeParentsHeightDifference = (popup) => {
        let result = 0;
        let windowScrollY = window.scrollY;
        let currentParent = popup.parentElement;

        // Cycle through parents and check if their position is relative or
        // container is reached on which popups are supposed to adjust themselves
        while(!currentParent.matches('.container.is-fluid')) {
            if (j(currentParent).css('position') === 'relative') {

                // Add scrolled height of found element to result
                result += currentParent.getBoundingClientRect().top + windowScrollY;
            }
            
            currentParent = currentParent.parentElement;
            
            // Do nothing if no next parent is found
            if(currentParent == null) {
                return 0;
            }
        }

        return result;
    }

    const activatePopup = (popup) => {
        let windowHeight = window.innerHeight;
        let popupDimensions = null;
        let top = null;
        let offsetTop = 60;
        let navHeight = 90;

        popup.classList.add('is-active');
        popupDimensions = popup.getBoundingClientRect();

        // If Browser window is higher than popup, center popup vertically
        /* if (windowHeight > popupDimensions.height ) {
            top = window.scrollY + windowHeight / 2 - popupDimensions.height / 2;
        } */
        if ((windowHeight - navHeight) > popupDimensions.height ) {
            top = window.scrollY + windowHeight / 2 - popupDimensions.height / 2;
        }
        // If popup cannot be displayed entirely in browser window, place it 60 pixels below current position
        else {
            top = window.scrollY + offsetTop;
        }

        top -= (popup.classList.contains('second-level-popup'))
            ? offsetTop
            : relativeParentsHeightDifference(popup);

        popup.style.top = `${top}px`;
    }

    const deactivatePopup = (popup) => {
        popup.classList.remove('is-active');
    }

    const registerClose = (popup) => {
        let id = popup.id;
        let cancel = document.getElementById(`${id}-cancel`);

        cancel.addEventListener('click', () => deactivatePopup(popup));
    }

    const registerOpen = (popup) => {
        let id = popup.id;
        let open = document.getElementById(`${id}-trigger`);

        open.addEventListener('click', () => activatePopup(popup));
    }

    popups.forEach(popup => {
        let id = popup.id;

        if (document.getElementById(`${id}-cancel`) != undefined) {
            registerClose(popup);
        }
        
        if (document.getElementById(`${id}-trigger`) != undefined) {
            registerOpen(popup);
        }

        if (popup.classList.contains('activate-on-startup')) {
            activatePopup(popup);
        }
    });
}

//only execute if page contains popups
if (document.querySelector('.modal.popup') != null) {
    initializePopups();
}