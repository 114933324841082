'use strict';

const initializeFormFunctions = () => {
    let confirmBrowserClose = false;

    // Function for activating confirm message if page is unloaded
    const formWarning = (e = null) => {
        if(e && e.target.type === 'search') { // exclude filter search fields
            return;
        }
        confirmBrowserClose = true;
    }
    
    // Function for triggering a scroll event - used by select2 for readjusting bottomized side menu in some cases
    const triggerScrollEvent = () => {
        window.dispatchEvent(new Event('scroll'));
    }
    
    // Function for setting an event listener to activate a confirm message if page is unloaded with a partially filled form
    const initializeFormWarning = () => {
        const forms = document.querySelectorAll('form');
    
        $(window).bind('beforeunload', function() {
            if (!confirmBrowserClose) {
                return;
            }
            else {
                return "Wenn Du die Seite verlässt, gehen nicht gespeicherte Informationen verloren. Möchtest Du die Seite wirklich schließen?";
            }
        });
    
        $("form").bind("submit", function() {
            confirmBrowserClose = false;
        });
    
        forms.forEach(form => form.addEventListener('change', (e) => formWarning(e)));
    }

    const initializePreventMultipleFormSubmits = () => {
        const forms = document.querySelectorAll('form');

        const disableSubmitButton = (e) => {
            if (e.submitter) {
                e.submitter.classList.add('disabled');
            }
        }

        forms.forEach(form => form.addEventListener('submit', disableSubmitButton));
    }

    // Function for displaying correct styles to icon checkboxes if their status changes
    const initializeIconCheckboxWatcher = () => {
        const setupCheckboxes = (e) => {
            // Select all icon checkboxes and update their styling
            e.currentTarget.querySelectorAll('.icon-checkbox')
                .forEach(element => updateStyling(element));
        }
    
        const updateStyling = (element) => {
            let input = element.querySelector('input');
            let label = element.querySelector('label');
    
            if (input.checked) {
                label.classList.add('selected');
            }
            else {
                label.classList.remove('selected');
            }
            if (input.disabled) {
                label.classList.add('disabled');
            }
        }

        // change styles when page is loaded
        document.querySelectorAll('.icon-checkbox')
            .forEach(element => updateStyling(element));

        // Add click event listener to all checkbox containers
        document.querySelectorAll('.checkbox-container')
            .forEach(element => element.addEventListener('click', setupCheckboxes));
    }
    
    // Function for setting up textareas that increase/decrease in size according to user input
    const initializeTextareas = () => {
        const textAreas = document.querySelectorAll('textarea');
    
        if (textAreas.length > 0) {
            const minHeight = 40; // Set minimum height for textareas, in case they have display: none on page load
            $('textarea').each(function () {
                let height = (this.scrollHeight === 0 ? minHeight : this.scrollHeight);
                this.setAttribute('style', 'height:' + (height) + 'px;overflow-y:hidden;');
              }).on('input', function () {
                this.style.height = 'auto';
                this.style.height = (this.scrollHeight) + 'px';
              });
        }
    }
    
    // FUnction for initializing select2 fields
    const initializeSelect2 = () => {
        const select2Settings = {
            placeholder: 'Option auswählen',
            width: '100%',
            dropdownCssClass: 'select-dropdown',
            allowClear: true,
        }
    
        if (document.querySelector('.advanced-select') != null) {
            j('.advanced-select:not(.no-search)').select2(select2Settings);
            //disable search bar for selectboxes with 'no-search' class
            select2Settings.minimumResultsForSearch = '-1';
            j('.advanced-select.no-search').select2(select2Settings);

            // Trigger a scroll event on selection to readjust bottomized side menu in some cases.
            // Normally body click events do this - though on select2 option boxes those events are not triggered
            $('.advanced-select').on('select2:select', triggerScrollEvent);
    
            // Activate form warning behavior if select2 is changed
            $('.advanced-select').on('change', formWarning);
        }

        //add Bulma select class for arrow display
        document.querySelectorAll('.select2-selection').forEach(element => element.classList.add('select'));
    }

    // Function for setting up text fields that can be convertet to inputs for editing on the fly
    const initializeEditableForms = () => {
        j('.tile-form .is-editable[readonly]').click(function () {
            j(this).prop('readonly', false);
            j(this).off('click');
        });
        
        j('.editable-form')
        .submit(function () {
            j('input[readonly]', this).prop('disabled', true);
        });
        
        j('.editable-form :input').change(function () {
            $(this).closest('form').find('button.is-invisible').removeClass('is-invisible');
        });
        
        j('input[maxlength]').each(function () {
            $(this).keyup(function () {
                let label = $(this).parent().find('.maxlength-label');
                if (!label.length) {
                    label = $('<span class="maxlength-label"></span>');
                    $(this).parent().append(label);
                }
                label.text(this.value.length + ' / ' + this.maxLength);
            });
        });
    };
    
    // Initialization goes here
    if (document.querySelector('form') != null) {
        initializeFormWarning();
        initializePreventMultipleFormSubmits();
    }

    if (document.querySelector('.checkbox-container') != null) {
        initializeIconCheckboxWatcher();
    }
    
    initializeTextareas();
    initializeSelect2();
    initializeEditableForms();
}

window.addEventListener('DOMContentLoaded', initializeFormFunctions);