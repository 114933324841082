'use strict';

// Function for adding/removing insurance type details when creating new insurance contract for property
const initializeInsuranceTypeSelection = () => {
    const insuranceTypes = document.querySelectorAll('#insurance-type-selectors .icon-checkbox');
    const insuranceTypeDetails = document.querySelectorAll('.insurance-type-details');

    const toggleDetails = () => {
        // Cycle through all input fields 
        insuranceTypes.forEach((element, index) => {

            // Slide Element up/down depending on checked status
            if (element.querySelector('input').checked) {
                j(insuranceTypeDetails[index]).slideDown();
                j(insuranceTypeDetails[index]).find("[type=date]").prop('required', true);
            }
            else {
                let detailElement = insuranceTypeDetails[index];
                if (detailElement) {
                    j(detailElement).slideUp();
                    detailElement.querySelectorAll('input').forEach(inputElement => {
                        inputElement.value = '';
                        inputElement.required = false;
                    });
                }
            }
        });
    }

    toggleDetails(); // sets Visibility on page load
    document.querySelector('#insurance-type-selectors').addEventListener('click', toggleDetails);
}

const initializeDateFiller = () => {
    const dateFields = Array.from(document.querySelectorAll('.date-input'));
    
    const autofillFields = (e) => {
        dateFields.forEach(field => {
            let date = new Date(field.value);

            // Update field until date is valid and year has at least four digits
            // Since undefined < 999 == false, these two checks need to be separate
            if (field.required && !field.value || field.required && date.getFullYear() < 999)
                field.value = e.target.value;
        })
    }

    dateFields.forEach(field => $(field).on('change', (e) => autofillFields(e)));
}


//only execute if insurance type selectors exist
if (document.querySelector('#insurance-type-selectors') != null) {
    initializeInsuranceTypeSelection();
    initializeDateFiller();
}