'use strict';
const initializeChartBuilder = () => {
    const iconGradientId = 'icon-gradient';
    const defaultHeight = 350;
    const defaultPadding = 80;
    
    // Pie Chart default Constants
    const defaultRadius = defaultHeight / 2 - defaultPadding;

    const defaultFormatter = new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 0,
    });
    const damageTypes = {
        1 : '\ue90a', // sf-elementar
        2 : '\ue90b', // sf-feuerbrandblitz
        3 : '\ue90c', // sf-glas
        4 : '\ue90e', // sf-leitungswasser
        5 : '\ue90f', // sf-sturmhagel
        6 : '\ue910', // sf-vandalismus
        7 : '\ue90d', // sf-haustechnik
    };

    const addDragHandler = (chart, padding = defaultPadding) => {
        let offsetX = chart.data()[0].offsetX;
        let offsetY = chart.data()[0].offsetY;
        let newX;
        const setNewX = (coordinate) => {

            if (coordinate > Math.floor(getChartWidth(chart) - padding + offsetX)) {
                newX = Math.floor(getChartWidth(chart) - padding + offsetX);
            }
            else if (coordinate < Math.floor(-getChartContainerWidth(chart) + padding + offsetX)) {
                newX = Math.floor(-getChartContainerWidth(chart) + padding + offsetX);
            }
            else {
                newX = Math.floor(d3.event.x);
            }
        }
        chart.call(
            d3.drag()
            .on('start', () => { offsetX = chart.data()[0].offsetX })
            .on('drag', (d) => {
                chart.select('.chart-container')
                .call(() => setNewX(d3.event.x))
                .attr('x', d.x = newX)
                .attr('transform', `translate(${newX}, ${offsetY})`);
            })
        );
    }
    const getChartContainerWidth = (chart) => { return chart.select('.chart-container').node().getBoundingClientRect().width };
    const getChartWidth = (chart) => { return chart.node().getBoundingClientRect().width };
    const getDamageTypeIcon = (id) => {
        if (id in damageTypes) {
            return damageTypes[id];
        }
        else {
            return 'missing';
        }
    }
    const getIconGradient = (svg) => {
        // Gradient color for icons. This function sets an Element with ID when first called.
        if (document.getElementById(iconGradientId) == undefined) {
            let gradient = svg.append('defs')
            .append('linearGradient')
            .attr("id", `${iconGradientId}`);
    
            gradient.append("stop")
                .attr('class', 'start')
                .attr("offset", "0%");
    
            gradient.append("stop")
            .attr('class', 'stop')
                .attr("offset", "100%");
        }
        return `url(#${iconGradientId})`;
    }
    const makeDivisibleByTen = (number) => {
        if (!isNaN(number) && number % 10 != 0) {
            while (number % 10 != 0) {
                number++;
            }
        }
        return number;
    }
    const repositionChart = (chart) => {
        let newX;
        let offsetX = chart.data()[0].offsetX;
        let offsetY = chart.data()[0].offsetY
     
        const getNewX = () => {
            let chartWidth = getChartWidth(chart);
            let containerWidth = getChartContainerWidth(chart);
            if (containerWidth < chartWidth) {
                return Math.floor(chartWidth / 2 - containerWidth / 2 + offsetX);
            }
            else {
                return offsetX;
            }
        }

        chart.select('.chart-container')
            .call(() => { newX = getNewX(); })
            .call((d) => { d.data()[0].x = newX })
            .attr('x', newX)
            .attr('transform', `translate(${newX},${offsetY})`);
    }

    const parametersAreValid = (data, containerSelector) => {
        if (data.length > 0 && document.querySelector(containerSelector) != undefined) {
            return true;
        }
        else {
            console.log('chart data is empty or chart container does not exist');
            return false;
        }
    }




    const buildVerticalBarChart = (data, containerSelector, formatter = defaultFormatter) => {
    /******** Error handling *********/
        if (!parametersAreValid(data, containerSelector)) {
            return;
        }
    /********* General values ***************/
        const first = data[0].date;
        const last = data[data.length - 1].date;
        const months = last.getMonth() - first.getMonth() + 12 * (last.getYear() - first.getYear()) + 1;

        const width = 480;
        const height = defaultHeight;
        const barWidth = width / months;
        const padding = defaultPadding;

    /********* Scales used for size adjustments **********/
        const xScale = d3.scaleLinear().domain([0, months]).range([0, width]);
        const yScale = d3.scaleLinear().range([height, 0]);

    /********** Chart components ***************/
        // SVG element. Stretches over entire tile
        const barChart = d3.select(containerSelector)
            .append('svg')
            .data([{x : padding, offsetX : padding, offsetY : padding}])
            .attr('height', height + padding * 2)
            .attr('data-chart-type', 'vertical-bar-chart');
        
        // Chart container. Has size of actual chart
        const chartContainer = barChart.append('g')
            .attr('class', 'chart-container');

        // Axes are generated here
        let date = new Date(first.getTime());
        date.setDate(1);
        let tickValues = [];
        for (let index = 0; index < months; index++) {
            let copyDate = new Date(date.getTime());
            copyDate.setMonth(copyDate.getMonth() + index);
            tickValues.push(copyDate);
        }
        const xAxis = chartContainer.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3
                .axisBottom(xScale)
                .tickSize(0).tickPadding(20).tickValues(tickValues)
            )
            .attr('text-anchor', 'begin')
            .attr('class', 'axis');

        xAxis.selectAll('.tick')
            .attr('transform', (d) => { return 'translate('+ (xScale(d.getMonth() - first.getMonth() + (d.getYear() - first.getYear())*12) + barWidth/2 - 6.5) +',0)'; });

        xAxis.selectAll('.tick text')
            .attr('fill', null)
            .attr('dx', -13)
            .attr('dy', 0)
            .attr('text-anchor', 'begin')
            .attr('transform', 'rotate(90, 0, 20)')
            .call((d) => {
                d.each((tickValue, index, tickNodeList) => {
                    let currentTick = d3.select(tickNodeList[index]);
                    currentTick.text(tickValue.toLocaleString('default', {month: 'short', year: '2-digit'}));
                    // currentTick.append('tspan')
                    //     .text(tickValue.toLocaleString('default', {month: 'short'}))
                    //     .attr('text-anchor', 'begin')
                    //     .attr('transform', 'rotate(90)')
                    //     .attr('dy', 0);
                    // currentTick.append('tspan')
                    //     .text(tickValue.toLocaleString('default', {year: '2-digit'}))
                    //     .attr('x', barWidth / 2)
                    //     .attr('dy', '20');
                })
            })

        const yAxis = chartContainer.append('g');
        
        yAxis.attr('class', 'axis')
            .selectAll('.tick text')
            .attr('fill', null);

        // Animations
        const animateStartup = (element) => {
            element.transition('animateStartup')
            .duration(1000)
            .ease(d3.easeQuad)
            .attr('y', (d) => { return yScale(d.amount); })
            .attr('height', (d) => { return height - yScale(d.amount); });
        }

    /*********** Chart Functions ***********/
        const updateChart = (data) => {
            if (data === undefined) {
                console.log('data is undefined');
                return;
            }
            let max = d3.max(data, (d) => { return d.amount; });
            // Update Y-Scale
            yScale.domain([0, Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max)))]);

            // Update Y-Axis
            yAxis.call(d3.axisLeft(yScale).tickValues(
                d3.range(0,
                    Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max))) + 1,
                    makeDivisibleByTen(Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max)))) / 10
                )).tickSize(0).tickPadding(10).tickFormat((d) => { return formatter.format(d); }));

            // Update Bars
            let bars = chartContainer.selectAll('.bar')
                .data(data);

            let newBars = bars.enter()
                .append('g')
                .attr('class', 'bar');

            newBars.append('rect');
            newBars.append('text');

            bars.exit().remove();
            newBars.merge(bars).call((d) => drawBars(d));
        }

        const drawBars = (bars) => {
            bars.select('rect')
                .attr('x', (d, i) => { return xScale(d.date.getMonth() - first.getMonth() + (d.date.getYear() - first.getYear())*12); })
                .attr('width', barWidth)
                .attr('y', () => { return yScale(0); })
                .attr('height', 0)
                .attr('fill', (d, i) => { return (i % 2 === 0) ? '#777' : '#ccc'; })
                .call(animateStartup);
                
            bars.select('text')
                .attr('x', (d, i) => { return xScale(d.date.getMonth() - first.getMonth() + (d.date.getYear() - first.getYear())*12) + barWidth/2; })
                .attr('y', (d) => { return yScale(d.amount) - 30; })
                .attr('transform', (d) => {
                    let x = xScale(d.date.getMonth() - first.getMonth() + (d.date.getYear() - first.getYear())*12) + barWidth/2;
                    let y = yScale(d.amount) - 16;
                    return 'rotate(90,' + x + ',' + y + ')';
                })
                .attr('dx', 0)
                .attr('dy', '1.2em')
                .attr('text-anchor', 'end')
                .text( (d) => { return formatter.format(d.amount)})
                .attr('fill', '#000');
        }

        updateChart(data);
        addDragHandler(barChart);
        repositionChart(barChart);
        barChart.updateChart = updateChart;
        return barChart;
    }




    const buildHorizontalBarChart = (data, containerSelector, formatter = defaultFormatter) => {
    /******** Error handling *********/
        if (!parametersAreValid(data, containerSelector)) {
            return;
        }
    /********* General values ***************/
        const barWidth = 50;
        const width = 12 * barWidth;
        const height = barWidth * data.length;
        const iconRadius = 20;
        const iconOffset = (barWidth - iconRadius * 2) / 2;
        const padding = defaultPadding/2;

    /********* Scales used for size adjustments **********/
        const max = d3.max(data, (d) => { return d.amount; });
        const xScale = d3.scaleLinear().range([0, width]);
        const yScale = d3.scaleLinear().domain([0, data.length]).range([height, 0]);

    /********** Chart components ***************/
        // SVG element. Stretches over entire tile
        const barChart = d3.select(containerSelector)
            .append('svg')
            .data([{x : padding, offsetX : iconRadius * 2.5, offsetY : padding}])
            .attr('height', height + padding * 2)
            .attr('data-chart-type', 'horizontal-bar-chart')
            .call((d) => getIconGradient(d));

        // Chart container. Has size of actual chart
        const chartContainer = barChart.append('g')
            .attr('class', 'chart-container');

        // Axes are generated here
        const xAxis = chartContainer.append('g')
            .attr('transform', `translate(0,${height})`)
            .attr('class', 'axis');

        xAxis.selectAll('.tick text')
            .attr('fill', null);

        const yAxis = chartContainer.append('g')
            .call(d3.axisLeft(yScale).tickSize(0).tickPadding(10).tickFormat( (d) => { return ''; }))
            .attr('class', 'axis')
            .selectAll('.tick text')
            .remove();

        // Animations
        const animateStartup = (element) => {
            element.transition('animateStartup')
                .duration(1000)
                .ease(d3.easeQuad)
                .attr('width', (d) => { return xScale(d.amount); });
        }

    /*********** Chart Functions ***********/
        const updateChart = (data) => {
            if (data === undefined) {
                console.log('data is undefined');
                return;
            }
            // Update X-Scale
            xScale.domain([0, Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max)))]);

            // Update X-Axis
            xAxis.call(d3.axisBottom(xScale).tickValues(
                d3.range(0,
                    Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max))),
                    (Math.ceil(max/Math.pow(10,Math.floor(Math.log10(max)))) * Math.pow(10,Math.floor(Math.log10(max))) / 5)
                ))
                .tickSize(0)
                .tickPadding(10).tickFormat((d) => { return formatter.format(d) }));

            // Update Bars
            let bars = chartContainer.selectAll('.bar')
                .data(data);

            let newBars = bars.enter()
                .append('g')
                .attr('class', 'bar');

            newBars.append('rect');
            newBars.append('text');

            let icon = newBars.append('g').attr('class', 'bar-icon-container');
            icon.append('circle')
            icon.append('text').attr('class', 'bar-icon sf-icon');

            bars.exit().remove();
            newBars.merge(bars).call((d) => drawBars(d));
        }

        const drawBars = (bars) => {
            bars.select('.bar-icon-container circle')
                .attr('r', iconRadius)
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('transform', (d, i) => { return `translate(${-padding / 1.5},${yScale(i) - iconRadius - iconOffset})` });
                
            bars.select('.bar-icon')
                .attr('transform', (d, i) => { 
                    let x = -padding / 1.5;
                    let y = yScale(i) - iconOffset - 3;
                    return `translate(${x},${y})`;
                })
                .attr('text-anchor', 'middle')
                .attr('fill', `url(#${iconGradientId})`)
                .text((d) => { return getDamageTypeIcon(d.damageType)});

            bars.select('rect')
                .attr('x', (d, i) => { return xScale(0); })
                .attr('width', 0)
                .attr('height', barWidth)
                .attr('y', (d, i) => { return yScale(i) - barWidth; })
                .attr('fill', (d, i) => { return (i % 2 === 0) ? '#777' : '#ccc'; })
                .call(animateStartup);

            bars.select('text')
                .attr('x', (d, i) => { return xScale(d.amount) + padding; })
                .attr('y', (d, i) => { return yScale(i) - barWidth; })
                .attr('dy', barWidth / 1.5)
                .attr('text-anchor', 'middle')
                .text( (d) => { return formatter.format(d.amount)})
                .attr('fill', '#000');
        }

        updateChart(data);
        repositionChart(barChart);
        addDragHandler(barChart);
        barChart.updateChart = updateChart;
        return barChart;
    }




    // Pie Chart Helpers Start
    const setSliceColors = (slices, radius = defaultRadius) => {
        const arc = d3.arc().innerRadius(0.25*radius).outerRadius(radius);

        slices.select('path')
            .style("opacity", (d, i, n) => {
                let minimumOpacity = 0.1;
                if (i + 1 === n.length && n.length != 1) {
                    return minimumOpacity;
                }
                let opacityDecay = ((1 - minimumOpacity) / (n.length - 1)).toFixed(3);
                return (1 - (opacityDecay * i)).toFixed(3);
            })
            .attr("class", "area")
            .attr('stroke', '#fff')
            .attr("d", (d) => { return arc(d); });
    }
    const setPercentages = (slices, formatter, radius = defaultRadius, padding = defaultPadding) => {
        const percentageArc = d3.arc().outerRadius(radius - padding).innerRadius(radius - padding);

        slices.select('text')
        .attr("transform", (d) => { return `translate(${percentageArc.centroid(d)})`; })
        .attr('text-anchor', 'middle')
        .text((d) => formatter.format(d.value));
    }
    const setPercentageTextColor = (slices) => {
        slices.select('text')
        .attr('class', (d, i, n) => {
            return (i + 1 === n.length && n.length != 1) ?  'black' :  'white';
        })
    }
    // Pie Chart Helpers End



    const buildIconPieChart = (data, containerSelector, formatter = defaultFormatter) => {
    /******** Error handling *********/
        if (!parametersAreValid(data, containerSelector)) {
            return;
        }
    /********* General values ***************/
        const height = defaultHeight;
        const padding = defaultPadding/2;
        const radius = height / 2 - padding;
        const iconRadius = 24;

    /********** Chart components ***************/
        // SVG element. Stretches over entire tile
        const pieChart = d3.select(containerSelector)
            .append('svg')
            .data([{x: padding, offsetX : radius, offsetY: radius + padding * 2 }])
            .attr('height', height + padding * 2)
            .attr('data-chart-type', 'icon-pie-chart')
            .call((d) => getIconGradient(d));

        // Chart container. Has size of actual chart
        const chartContainer = pieChart.append('g')
            .attr('class', 'chart-container');

        const pie = d3.pie().value((d) => { return d.amount; }).sort(null);
        const iconArc = d3.arc().outerRadius(radius + padding).innerRadius(radius + padding);

    
    /*********** Chart Functions ***********/
        const updateChart = (data) => {
            if (data === undefined) {
                console.log('data is undefined');
                return;
            }
            // Update Slices
            let slices = chartContainer.selectAll('.slice')
                .data(pie(data))

            let newSlices = slices.enter()
                .append('g')
                .attr('class', 'slice');

            newSlices.append('path');
            newSlices.append('text');

            let icon = newSlices.append('g').attr('class', 'pie-icon-container');
            icon.append('circle')
            icon.append('text').attr('class', 'pie-icon sf-icon');

            slices.exit().remove();
            newSlices.merge(slices).call((d) => drawSlices(d));
            
            // Update offset value, since new data can change pie chart size
            pieChart.data()[0].offsetX = getChartContainerWidth(pieChart) / 2;
        }

        const drawSlices = (slices) => {
            setSliceColors(slices, radius);
            setPercentages(slices, formatter, radius, padding);
            setPercentageTextColor(slices);

            slices.select('.pie-icon-container circle')
                .attr('r', iconRadius)
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('transform', (d) => { return `translate(${iconArc.centroid(d)})`; })

            slices.select('.pie-icon')
                .attr('transform', (d) => { 
                    let x = iconArc.centroid(d)[0];
                    let y = iconArc.centroid(d)[1] + iconRadius - 3; // 3 is required because text container is slightly bigger than circle
                    return `translate(${x},${y})`;
                })
                .attr('text-anchor', 'middle')
                .attr('fill', `url(#${iconGradientId})`)
                .text((d) => {return getDamageTypeIcon(d.data.damageType)});
        }


        updateChart(data);
        repositionChart(pieChart);
        addDragHandler(pieChart);
        pieChart.updateChart = updateChart;
        return pieChart;
    }




    const buildLabelPieChart = (data, containerSelector, formatter = defaultFormatter) => {
    /******** Error handling *********/
        if (!parametersAreValid(data, containerSelector)) {
            return;
        }
    /********* General values ***************/
        const height = defaultHeight;
        const padding = defaultPadding/2;
        const radius = height / 2 - padding;

        // Label specific Values
        const textPadding = 5;
        const verticalLineHeight = 20;
        const treshold = radius / 2; // Labels in between treshold and -treshold have no vertical line

    /********** Chart components ***************/
        // SVG element. Stretches over entire tile
        const pieChart = d3.select(containerSelector)
            .append('svg')
            .data([{x: padding, offsetX : radius, offsetY: radius + padding * 2 }])
            .attr('height', height + padding * 2)
            .attr('data-chart-type', 'label-pie-chart')
            .call((d) => getIconGradient(d));

        // Chart container. Has size of actual chart
        const chartContainer = pieChart.append('g')
            .attr('class', 'chart-container');
        const pie = d3.pie()
            .value((d) => { return d.amount; })
            .sort(null);

        const labelArc = d3.arc().outerRadius(radius - radius / 10).innerRadius(radius - radius / 10);

    
    /*********** Chart Functions ***********/
        const updateChart = (data) => {
            if (data === undefined) {
                console.log('data is undefined');
                return;
            }
            // Update Slices
            let slices = chartContainer.selectAll('.slice')
                .data(pie(data))

            let newSlices = slices.enter()
                .append('g')
                .attr('class', 'slice');

            newSlices.append('path');
            newSlices.append('text');

            // Update Labels
            let label = newSlices.append('g').attr('class', 'pie-label-container');
            label.append('path').attr('class', 'label-path');
            label.append('text').attr('class', 'label-text');

            slices.exit().remove();
            newSlices.merge(slices).call((d) => drawSlices(d));
            
            // Update offset value, since new data can change size of pie chart container
            pieChart.data()[0].offsetX = getChartContainerWidth(pieChart) / 2;
        }

        const drawSlices = (slices) => {
            setSliceColors(slices, radius);
            setPercentages(slices, formatter, radius, padding);
            setPercentageTextColor(slices);

            // Generate Text Labels
            let label = slices.select('.pie-label-container');

            // Label Text
            let labelTexts = label.select('.label-text');

            labelTexts.text((d) => { return d.data.cause; })
                .attr('text-anchor', (d) => { return (labelArc.centroid(d)[0] <= 0) ? 'start' : 'end'; })
                .attr('x', (d, i, n) => {
                    let x = labelArc.centroid(d)[0];
                    let orientation = (x >= 0 ) ? 1 : -1;
                    let horizontalLine = n[i].getComputedTextLength() + padding;

                    return x + horizontalLine * orientation;
                })
                .attr('y', (d, i, n) => {
                    let y = labelArc.centroid(d)[1];
                    let verticalLine =
                        (y > treshold) ? verticalLineHeight :
                        (y < -treshold) ? -verticalLineHeight : 0;
                    //verticalLine = 0;
                    return y + verticalLine - textPadding;
                })
                .attr('dy', 0);

            // Label Lines
            let labelPaths = label.select('.label-path');
                
            labelPaths.attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('fill', 'none')
                .attr('x', (d) => {return labelArc.centroid(d)[0]})
                .attr('y', (d) => {return labelArc.centroid(d)[1]})
                .attr('d', (d, i, n) => {
                    let texts = labelTexts.nodes();
                    let x = n[i].attributes.x.value;
                    let y = n[i].attributes.y.value;
                    let horizontalLine = texts[i].attributes.x.value - x;
                    let verticalLine = texts[i].attributes.y.value - y + textPadding;

                    return `M${x},${y} l0,${verticalLine} l${horizontalLine},0`;
                });

            separateLabels();
        }

        const separateLabels = () => {
            let alpha = 1;
            let spacing = 40;
            let repeat = false;
            let labels = chartContainer.selectAll('.pie-label-container');
            let texts = labels.select('.label-text');
            let upperTexts = texts.filter((d, i, n) => { return n[i].attributes.y.value <= 0; });
            let lowerTexts = texts.filter((d, i, n) => { return n[i].attributes.y.value > 0; });
            let paths = labels.select('.label-path');

            const calculateSeparation = (a, da, y1, j, o, orientation = '') => {
                // Label that the current one is being compared to
                let b = o[j];
                // a & b are the same element and don't collide.
                if (a == b) return;
                let db = d3.select(b);
                // a & b are on opposite sides of the chart and don't collide
                if (da.attr("text-anchor") != db.attr("text-anchor")) return;
                // Now let's calculate the distance between these elements. 
                let y2 = db.attr("y");
                let deltaY = y1 - y2;
                // Our spacing is greater than our specified spacing, so they don't collide.
                if (Math.abs(deltaY) > spacing) return;
                // If the labels collide, we'll push each of the two labels up and down a little bit.
                repeat = true;
                let sign = deltaY > 0 ? 1 : -1;
                let adjust = sign * alpha;

                if(db.attr('y') > -textPadding ) {
                    db.attr('y', -textPadding);
                }
                if(db.attr('y') > -textPadding ) {
                    db.attr('y', -textPadding);
                }

                da.attr('y',+y1 + adjust)
                db.attr('y',+y2 - adjust);

                if(db.attr('y') > -textPadding && orientation === 'up') {
                    db.attr('y', -textPadding);
                }

                if (da.attr('y') < textPadding && orientation === 'down') {
                    da.attr('y', textPadding);
                }

                if (repeat) {
                    // Redraw label lines
                    paths.attr('d', (d, i, n) => {
                        let textNodes = texts.nodes();
                        let x = n[i].attributes.x.value;
                        let y = n[i].attributes.y.value;
                        let horizontalLine = textNodes[i].attributes.x.value - x;
                        let verticalLine = textNodes[i].attributes.y.value - y + textPadding;
    
                        return `M${x},${y} l0,${verticalLine} l${horizontalLine},0`;
                    });

                    setTimeout(separateLabels, 50);
                }
            }

            // Setup first upper and last lower left slice, since they are in different collision sectors
            let firstUpperLeft = d3.select(upperTexts.filter((d, i, n) => { return n[i].attributes.x.value <= 0; })._groups[0][0]);
            let lastLowerLeft = lowerTexts.filter((d, i, n) => { return n[i].attributes.x.value <= 0; })._groups;
            lastLowerLeft = d3.select(lastLowerLeft[0][lastLowerLeft[0].length - 1]);

            firstUpperLeft.each((d, i, n) => {
                let a = n[i]; // current Label
                let da = d3.select(a);
                let y1 = da.attr("y");
                lastLowerLeft.each((d, j, o) => {
                    calculateSeparation(a, da, y1, j, o);
                });
            });

            // Collision for upper quadrants
            upperTexts.each((d, i, n) => {
                let a = n[i]; // current Label
                let da = d3.select(a);
                let y1 = da.attr("y");

                upperTexts.each((d, j, o) => {
                    calculateSeparation(a, da, y1, j, o, 'up');
                });
            });

            // Collision for lower quadrants
            lowerTexts.each((d, i, n) => {
                let a = n[i]; // current Label
                let da = d3.select(a);
                let y1 = da.attr("y");

                lowerTexts.each((d, j, o) => {
                    calculateSeparation(a, da, y1, j, o, 'down');
                });
            });
        }

        updateChart(data);
        repositionChart(pieChart);
        addDragHandler(pieChart);
        pieChart.updateChart = updateChart;

        return pieChart;
    }




    window.buildHorizontalBarChart = buildHorizontalBarChart;
    window.buildVerticalBarChart = buildVerticalBarChart;
    window.buildIconPieChart = buildIconPieChart;
    window.buildLabelPieChart = buildLabelPieChart;

    // Reposition Chart to stay visible upon window resize
    window.addEventListener('resize', () => d3.selectAll('.chart svg').each((d, i, n) => repositionChart(d3.select(n[i]))));
}

window.addEventListener('DOMContentLoaded', initializeChartBuilder);