



intro = introJs();

intro.setOptions({
    
    nextLabel : 'weiter',
    prevLabel : 'zurück',
    skipLabel : 'überspringen',
    doneLabel : 'schließen',

    toolTipPosition: 'bottom',
    tooltipClass : 'toolTipClass',
    highlightClass: 'highLightClass',
    
    hidePrev : true,
    hideNext : true,
    exitOnEsc : true,
    exitOnOverlayClick : true,
    showStepNumbers : false,
    keyboardNavigation : false,
    showButtons : false,
    showBullets : false,
    showProgress: false,
    scrollToElement: true,
    scrollTo : 'element',
    scrollPadding: 30,
    overlayOpacity: .5,
    disableInteraction: false,

});

initIntro = function(){
    if (activeIntro) {
        intro.start();
    }
}