// Currently Float fields cannot represent negative values. Support for negative values has to be implemented if required

'use strict';

const initializeFormsWithFloatInputs = () => {

    // Function for setting up text inputs exclusively for floats
    const initializeFloatInputs = () => {
        let inputs = Array.from(document.querySelectorAll('.float-input'));
        let separatorFound = false;
        
        // Function for allowing only a single separator
        const replaceMultipleSeparators = (match) => {
            let result = '';
            if (!separatorFound) {
                separatorFound = true;
                result = match;
            }
            
            return result;
        }
        
        // Function for allowing only numbers, one separator, and a maximum of two digits after separator
        const sanitizeValue = (input) => {
            separatorFound = false;
            let string = input.value.replace(/[^(\d|(.,))]/g, ''); // remove all but 0-9 and . or ,
            string = string.replace(/[,.]/g, replaceMultipleSeparators); // remove all but the first . or ,
            string = string.match(/\d+(?:[.,]\d{1,2})?/); // allow 1-infinite numbers, but only two after . or , (if present)
            
            return string;
        }
    
        // Function for float field validation, checking if input value respects min and max settings
        const validateValue = (input) => {
            let value = parseFloat(input.value.replace(/[,]/, '.'));
            let min = (input.dataset.min ? input.dataset.min : 0);
            let max = (input.dataset.max ? input.dataset.max : value +1);
            let result = false;
    
            if (value >= min && value <= max) {
                result = true;
            }
    
            return result;
        }
        
        // Function for sanitizing and validating float fields
        const handleInput = (input) => {
            input.value = sanitizeValue(input);

            // Check if input value is empty
            if (input.value === '' || input.value === null) {
                return;
            }
            // Check if input value is inside its min/max boundaries
            if (!validateValue(input)) {
                input.setCustomValidity('Der angegebene Wert liegt außerhalb des Gültigkeitsbereiches.');
            }
            else {
                input.setCustomValidity('');
            }
        }
        
        // Function for sanitizing that allows unlimited digits and separators
        const softSanitize = (input) => {
            // Only check for allowed characters, disregarding their quantity / positioning
            let value = input.value.replace(/[^(\d|(.,))]/g, ''); // allow only 0-9 . and ,
            input.value = value;
    
            input.setCustomValidity(''); // Remove validation error message after user starts typing
        }
        
        // Function for replacing dots with commas. Used on page load to set prefilled values
        const setupFloatInput = (input) => {
            input.value = input.value.replace(/[.]/, ','); // Show , instead of . for separator
    
            input.addEventListener('focusout', () => handleInput(input));
            input.addEventListener('keyup', () => softSanitize(input));
        }
    
        inputs.forEach(input => setupFloatInput(input));
    }

    // Function for replacing commas with dots on float fields before submitting to database
    const setupFormSubmit = () => {
        let forms = Array.from(document.querySelectorAll('form'))
            .filter(form => form.querySelector('.float-input') != null);
    
        // Database expects . as separator -> , has to be replaced with .
        const replaceCommasWithDots = (e) => {
            let floatInputs = Array.from(e.currentTarget.querySelectorAll('.float-input'));
            floatInputs.forEach(input => input.value = input.value.replace(/[,]/g, '.'));
        }
    
        forms.forEach(form => form.addEventListener('submit', replaceCommasWithDots));
        
    }
    
     //Initialization goes here
     if (document.querySelector('.float-input')) {
        setupFormSubmit();
        initializeFloatInputs();
    }
}

window.addEventListener('DOMContentLoaded', initializeFormsWithFloatInputs)