require('@babel/polyfill');
window.axios = require('axios');
window.d3 = require('d3');
window.Dropzone = require('dropzone');
require('events-polyfill');
window.introJs = require('intro.js/intro.js');
window.$ = window.jQuery = require('jquery');
window.lightbox = require('lightbox2');
require('nodelist-foreach-polyfill');
require('owl.carousel');
require('select2/dist/js/select2.full');
require('sidr/dist/jquery.sidr');
require('sticky-kit/dist/sticky-kit');

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
}

// Production steps of ECMA-262, Edition 5, 15.4.4.17
// Reference: http://es5.github.io/#x15.4.4.17
if (!Array.prototype.some) {
    Array.prototype.some = function(fun, thisArg) {
        'use strict';

        if (this == null) {
            throw new TypeError('Array.prototype.some called on null or undefined');
        }

        if (typeof fun !== 'function') {
            throw new TypeError();
        }

        var t = Object(this);
        var len = t.length >>> 0;

        for (var i = 0; i < len; i++) {
            if (i in t && fun.call(thisArg, t[i], i, t)) {
                return true;
            }
        }

        return false;
    };
}

// Array.filter polyfill
if (!Array.prototype.filter){
    Array.prototype.filter = function(func, thisArg) {
        'use strict';
        if ( ! ((typeof func === 'Function' || typeof func === 'function') && this) )
            throw new TypeError();
        
        var len = this.length >>> 0,
            res = new Array(len), // preallocate array
            t = this, c = 0, i = -1;
        if (thisArg === undefined){
            while (++i !== len){
            // checks to see if the key was set
            if (i in this){
                if (func(t[i], i, t)){
                res[c++] = t[i];
                }
            }
            }
        }
        else{
            while (++i !== len){
                // checks to see if the key was set
                if (i in this){
                    if (func.call(thisArg, t[i], i, t)){
                    res[c++] = t[i];
                    }
                }
            }
        }
        
        res.length = c; // shrink down array to proper size
        return res;
    };
}

// Array.find Polyfill
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
      value: function(predicate) {
       // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }
  
        var o = Object(this);
  
        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;
  
        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
  
        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];
  
        // 5. Let k be 0.
        var k = 0;
  
        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }
          // e. Increase k by 1.
          k++;
        }
  
        // 7. Return undefined.
        return undefined;
      },
      configurable: true,
      writable: true
    });
  }

Dropzone.autoDiscover = false;
$.extend(Dropzone.prototype.defaultOptions, {
    dictDefaultMessage: "Datei hier ablegen oder auswählen",
    dictFallbackMessage: "Dein Browser unterstützt das Ablegen von Dateien nicht.",
    dictFallbackText: "Bitte benutze das Formular, um Deine Datei hochzuladen.",
    dictFileTooBig: "Datei ist zu groß ({{filesize}}MiB). Maximale Dateigröße: {{maxFilesize}}MiB.",
    dictInvalidFileType: "Du kannst Dateien von diesem Typ nicht hochladen.",
    dictResponseError: "Server responded with {{statusCode}} code.",
    dictCancelUpload: "Upload Abbrechen",
    dictCancelUploadConfirmation: "Bist Du sicher, dass Du den Upload abbrechen möchtest?",
    dictRemoveFile: "Datei löschen",
    dictRemoveFileConfirmation: null,
    dictMaxFilesExceeded: "Du kannst keine weiteren Dateien hochladen."
});

lightbox.option({
    'fitImagesInViewport' : true,
    'wrapAround': true
  });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    Dropzone.prototype.defaultOptions.headers = {'X-CSRF-TOKEN': token.content};
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
