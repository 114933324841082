'use strict';

const checkForPhoneNumberGroups = () => {

    // Function for setting up buttons that add/remove phone number form components
    const initializeAddPhoneNumberButtons = () => {
        // Extract current array counter from last phone number added
        const getCounterFor = phoneNumber => {
            return phoneNumber.querySelector('.phone-type label').attributes.for.value.replace(/\D/g, '');
        }
    
        // Increase current array counter for a given attribute
        const setAttributeFor = (element, attribute, counter) => {
            let counterNext = (parseInt(counter) + 1).toString();
    
            // replace old counter with new one
            let replacement = element.getAttribute(attribute).replace(new RegExp(`${counter}`), counterNext);
    
            // set given attribute to the new value
            element.setAttribute(attribute, replacement);
        }
    
        // Collection of elements and attributes containing an array counter.
        // Returns value of the newest counter after applying it to all attributes
        const setAttributes = (phoneNumber) => {
            let counter = getCounterFor(phoneNumber);
            
            setAttributeFor(phoneNumber.querySelector('.phone-type label'), 'for', counter);
            setAttributeFor(phoneNumber.querySelector('.phone-type select'), 'id', counter);
            setAttributeFor(phoneNumber.querySelector('.phone-type select'), 'name', counter);
            setAttributeFor(phoneNumber.querySelector('.phone-number label'), 'for', counter);
            setAttributeFor(phoneNumber.querySelector('.phone-number input'), 'id', counter);
            setAttributeFor(phoneNumber.querySelector('.phone-number input'), 'name', counter);
    
            // Remove values from new fields
            phoneNumber.querySelector('.phone-number input').value = '';
    
            return (parseInt(counter) + 1);
        }
    
        const addPhoneNumber = (e) => {
            let buttonContainer = e.currentTarget.parentElement;
            let form = e.currentTarget.parentElement.parentElement;
            let newPhoneNumber = e.currentTarget.parentElement.previousSibling;
    
            // Exclude empty whitespace elements created by some browsers
            while (newPhoneNumber.nodeName != 'DIV') {
                newPhoneNumber = newPhoneNumber.previousSibling;
            }
            // Duplicate retrieved phone number node
            newPhoneNumber = newPhoneNumber.cloneNode(true);
    
            // Check if correct node is selected
            if (newPhoneNumber.classList.contains('phone-number-group') === false) {
                console.log("Incorrect node given to function addPhoneNumber. Function expects 'phone-number-group' class");
                return;
            }
            
            // Set attributes for new number fields, and retrieve the new counter
            let phoneNumberAmount = setAttributes(newPhoneNumber);
            form.insertBefore(newPhoneNumber, buttonContainer);
    
            // Display button to remove phone numbers if there is more than one number
            if (phoneNumberAmount >= 1) {
                buttonContainer.querySelector('.remove-number-button').classList.add('visible');
            }
    
            // Prepare Element for Select2
            let selectbox = newPhoneNumber.querySelector('.select');
            selectbox.removeChild(selectbox.querySelector('span'));
    
            selectbox = selectbox.querySelector('select');
    
            selectbox.removeAttribute('data-select2-id');
            selectbox.classList.remove('select2-hidden-accessible');
            selectbox.querySelectorAll('option').forEach(option => option.removeAttribute('data-select2-id'));
            
            // Create new select2 box
            j(`#${selectbox.attributes.id.value}`).select2({
                placeholder: 'Option auswählen',
                width: '100%',
                dropdownCssClass: 'select-dropdown',
                minimumResultsForSearch: '-1',
            });
        }
    
        const removePhoneNumber = (e) => {
            let buttonContainer = e.currentTarget.parentElement;
            let numberToRemove = e.currentTarget.parentElement.previousSibling;
    
            // Exclude empty text elements created by some browsers
            while (numberToRemove.nodeName != 'DIV') {
                numberToRemove = numberToRemove.previousSibling;
            }
    
            //Delete Select2 entries
            let selectbox = numberToRemove.querySelector('select');
            j(`#${selectbox.attributes.id.value}`).select2('destroy');
    
            //Stop displaying button to remove phone numbers, if no optional numbers exist
            if (getCounterFor(numberToRemove) === '1') {
                buttonContainer.querySelector('.remove-number-button').classList.remove('visible');
            }
    
            numberToRemove.remove();
        }
    
        document.querySelectorAll('.add-number-button').forEach(button => button.addEventListener('click', addPhoneNumber));
        document.querySelectorAll('.remove-number-button').forEach(button => button.addEventListener('click', removePhoneNumber));
    
    }

    // Initialization goes here
    if (document.querySelector('.phone-number-group') != null) {
        initializeAddPhoneNumberButtons();
    }
}

window.addEventListener('DOMContentLoaded', checkForPhoneNumberGroups);