'use strict';

const initializeDropdownCards = () => {
    const dropdownCards = Array.from(document.querySelectorAll('.dropdown-card'));

    const displayCard = (e) => {
        let card = e.currentTarget.querySelector('.dropdown-menu');

        if (card === null) {
            console.log('dropdown cards require bulma hoverable dropdown-menu to work');
            return;
        }

        // Reposition element slightly to make it hoverable more easily
        let x = e.clientX - 5;
        let y = e.clientY - 5;

        card.style.top = `${y.toString()}px`;
        card.style.left = `${x.toString()}px`;
    }

    dropdownCards.forEach(card => card.addEventListener('mouseenter', displayCard));
}

if (document.querySelector('.dropdown-card') != null) {
    window.addEventListener('DOMContentLoaded', initializeDropdownCards);
}