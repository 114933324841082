// Full tile elements like scroll tables or sliders that will not resize themselves automatically to main tile width
// need to be risezed through dynamic width.

// Currently dynamicWidth function only works for scroll tables and sliders. Implementation of additional elements requires
// code rewriting. The scroll-table class can be given to almost every div that needs to resize itself though,
// even if no actual table is inside. Remember to add has-scroll-table to parent tile to make it work.

'use strict';

const initializeDynamicWidthElements = () => {
    const carouselCollection = document.querySelectorAll('.carousel-wrapper');
    const scrollTableCollection = document.querySelectorAll('.scroll-table');
    
    // Function for calculating width a main tile element is supposed to have, before applying it to sliders/scroll tables
    const dynamicWidth = () => {
        // Set a scroll table or slider as reference for widths, whichever is currently present
        let referenceTile = (document.querySelector('.has-scroll-table') != null) ? 'scroll-table' : 'slider';
    
        // calculate the padding
        let paddings = j('.main-column').outerWidth() - j('.main-column > .container').width() + j(`.has-${referenceTile} .is-child`).eq(0).outerWidth() - j(`.has-${referenceTile} .is-child`).eq(0).width();
    
        // On responsive we need an additional padding
        if (j('.main-column .container').outerWidth() - j('.main-column > .container .is-ancestor').width() == 0) {
            paddings += j(`.is-parent.has-${referenceTile}`).eq(0).outerWidth() - j(`.is-parent.has-${referenceTile}`).eq(0).width();
        }
        
        // get menuwidth (in responsive we only need the paddings, in desktop we need additionally the menu-width)
        let menuWidth = j('#side-menu-bar').outerWidth();
        if (j('#side-menu-bar').css('position') == 'fixed') {
            menuWidth = paddings;
        } else {
            menuWidth += paddings;
        }
    
        // get page width from header (window.width includes the scrollbar) and subtract the menuWidth + paddings
        let width = (isNaN(menuWidth)) ? j('#navbar').width() - paddings : j('#navbar').width() - menuWidth;
    
        carouselCollection.forEach(carousel => carousel.style.width = (width - 32).toString() + "px");
        scrollTableCollection.forEach(scrollTable => scrollTable.style.width = (width).toString() + "px");
    }
    
    // Function for Owl Slider initialization
    const initializeSliders = () => {
        function displaySlider() {
            // Activation of slider after load goes here. Items should display only after slider loaded.
        }
        // Slider Data
        const sliderSettings = {
            itemElement: 'li',
            stageElement: 'ul',
            nav: true,
            dots: true,
            navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
            onInitialize: dynamicWidth,
            onInitialized: displaySlider,
            autoWidth: true,
            margin:20,
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items: 1
                },
                // breakpoint from 480 up
                480 : {
                    items: 2
                },
                // breakpoint from 768 up
                768 : {
                    items: 3   
                }
            }
        }
        j('.owl-carousel:not(.specific)').owlCarousel(sliderSettings);

        // Specific slider initialization
        const tariffSliderSettings = {
            itemElement: 'li',
            stageElement: 'ul',
            nav: true,
            dots: true,
            navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
            onInitialize: dynamicWidth,
            onInitialized: displaySlider,
            autoWidth: false,
            margin: 0, // the border
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items: 1
                },
                // breakpoint from 600 up
                666 : {
                    items: 2
                },
                // breakpoint from 900 up
                1000 : {
                    items: 3
                }
            }
        }
        j('#tariff-slider').owlCarousel(tariffSliderSettings);

        const certifiedContractorsSettings = {
            nav: true,
            dots: true,
            navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
            onInitialize: dynamicWidth,
            onInitialized: displaySlider,
            autoWidth: false,
            margin: 0, // the border
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items: 1
                },
                // breakpoint from 1240 up
                1240 : {
                    items: 2
                }
            }
        }
        let certifiedContractorsSlider = document.getElementById('certified-contractors-slider');
        if (certifiedContractorsSlider && Array.from(certifiedContractorsSlider.querySelectorAll('.column.partner')).length > 1) {
            j(certifiedContractorsSlider).owlCarousel(certifiedContractorsSettings);
        }
    }

    // Initialization goes here
    // Add dynamicWidth listener on window resize only if elements exist that require dynamicWidth
    if (carouselCollection.length > 0 || scrollTableCollection.length > 0) {
        dynamicWidth(); //set width of scrollable elements when page first loaded
        window.addEventListener("resize", dynamicWidth);
    }
    initializeSliders();
}

window.addEventListener('DOMContentLoaded', initializeDynamicWidthElements);