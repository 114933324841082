'use strict';

function initializeTabs() {
    let tabTriggerLists = document.querySelectorAll('.tab-trigger-list');

    function setupTabs(list) {
        let contentContainerId = list.dataset.contentContainerId;
        let tabTrigger = list.querySelectorAll('.tab-trigger');
        let tabContentElements = document.getElementById(contentContainerId).querySelectorAll('.tab-content');

        function toggleTabs(e) {
            let targetId = e.currentTarget.dataset.targetId;

            tabContentElements.forEach(element => element.classList.remove('is-active'));
            tabTrigger.forEach(element => element.classList.remove('is-active'));
            e.currentTarget.classList.add('is-active');
            document.getElementById(targetId).classList.add('is-active');
        }
        
        tabTrigger.forEach(trigger => trigger.addEventListener('click', toggleTabs));
    }

    tabTriggerLists.forEach(list => setupTabs(list));
}

window.addEventListener('DOMContentLoaded', initializeTabs);