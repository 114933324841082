'use strict';

const initializeTableFunctions = () => {

    // Function for Tables with rows that trigger an anchor on click
    const initializeClickableRows = () => {
        const activateInput = (e) => {
            if (e.target.classList.contains('document_edit') || e.target.parentElement.classList.contains('document_edit')) {
                return;
            }
            e.preventDefault();
            let triggeringElement = e.target;
    
            // Select all rows / listelements and check which one contains the element that was clicked on
            e.currentTarget.childNodes.forEach(child => {
                if (child.nodeName !== '#text' && child.contains(triggeringElement)) {
    
                    /*** Checkbox handling ***/
                    // Exclude 'select all' checkboxes. They are handled separately in initializeSelectAll()
                    if (!child.classList.contains('select-all-row')) {
                        let checkbox = (child.querySelector('input[type=checkbox]')) ? child.querySelector('input[type=checkbox]') : '';
    
                        // Check if row contains a checkbox and toggle it, preventing page redirection
                        // Only if a checkbox is actually visible
                        if (checkbox != '' && ($(triggeringElement).parents('.checkbox-column').length) || triggeringElement.classList.contains('checkbox-column')) {
                            checkbox.checked = !checkbox.checked;
                            child.classList.toggle('selected');
                            return;
                        }
                    }
    
                    /*** Anchor handling ***/
                    // Get destination href if available
                    let anchor = child.querySelector('a:not(.document-interaction-button)');
                    let destination = anchor ? anchor.attributes.href.value : '';
                    
                    // Go to destination if there is one set
                    if (destination != '') {
                        let target = (anchor.attributes.target) ? anchor.attributes.target.value : '_self';
    
                        window.open(destination, target);
                    }
                }
            });
        }
    
        document.querySelectorAll('.has-links').forEach(element => element.addEventListener('click', activateInput));
    }

    // Function for Tables with row specific search fields
    const initializeSearchFields = () => {
        const searchForms = Array.from(document.querySelectorAll('form.search-form'));
        const searchFields = Array.from(document.querySelectorAll('input.search-field'));

        const submitAllSearchInputs = (e) => {
            e.preventDefault();

            let formField = e.target.querySelector('.search-field');

            searchFields.forEach(field => { // Add all inputs containing values to current form
                if (field.value != '' && field !== formField) {
                    let newField = field.cloneNode();

                    newField.style.display = 'none';
                    e.target.appendChild(newField);
                }
            })

            // Place input of current form outside of form if empty.
            // Prevents empty parameter inside url while preserving layout visibility
            if (formField.value === '') {
                e.target.parentElement.appendChild(formField);
            }

            e.target.submit();
        }

        searchForms.forEach(field => field.addEventListener('submit', submitAllSearchInputs));
    }
    
    // Function for Table with a SelectAll Row 
    const initializeSelectAll = () => {
        const checkboxes = document.querySelectorAll('input[type=checkbox]');
        const masterCheckbox = document.querySelector('#select-all');
        const dataRows = document.querySelectorAll('.has-links tr');
    
        const selectAll = (e) => {
            e.preventDefault();
            masterCheckbox.checked = !masterCheckbox.checked;
            checkboxes.forEach(checkbox => checkbox.checked = masterCheckbox.checked);
            dataRows.forEach(row => {
                if (row.querySelector('input').checked) {
                    row.classList.add('selected');
                }
                else {
                    row.classList.remove('selected');
                }
            });
        }
    
        document.querySelector('.select-all-row').addEventListener("click", selectAll);
    }
    
    // Function for Table with a Menu for search/export/import/delete
    const initializeTopRightMenu = () => {
        let topMenuButtons = Array.from(document.querySelector('.tile-title-iconbar').children);
        let formActionButtons = document.querySelectorAll('.form-action-button');

        // Functions for Top Right Menu Activation
        const activateTopMenuButton = (button) => {
            topMenuButtons.forEach(element => {
                if (element.classList.contains('top-right-search')) {
                    return;
                }
                element.classList.remove('is-active')
            });
            button.classList.add('is-active');
        }

        const deactivateTopMenuButton = (button) => {
            button.classList.remove('is-active');
        }

        
        // Functions for Form Submission Button Activation
        const activateFormActionButton = (action) => {
            deactivateFormActionButtons();

            switch (action) {
                case 'delete' :
                    document.getElementById('form-action-delete-button').parentElement.classList.add('visible');
            }
        }

        const deactivateFormActionButtons = () => {
            formActionButtons.forEach(button => button.parentElement.classList.remove('visible'));
        }


        // Functions for showing / hiding Checkboxes
        const showCheckboxes = () => {
            let table = document.querySelector('table');

            table.querySelector('.select-all-row').classList.add('opened');
            table.querySelector('.has-links').classList.add('checkbox-opened');
        }

        const hideAllCheckboxes = () => {
            let table = document.querySelector('table');
            table.querySelectorAll('.checkbox-column').forEach(element => {
                // Deselect all checkboxes before hiding them
                (element.querySelector('input')) ? element.querySelector('input').checked = false : '';
            });
            table.querySelector('.select-all-row').classList.remove('opened');
            table.querySelector('.has-links').classList.remove('checkbox-opened');
            table.querySelectorAll('.selected').forEach(element => element.classList.remove('selected'));
        }


        // Function for showing / hiding Search Row
        const toggleSearch = () => {
            let searchRow = document.querySelector('.search-row');
            searchRow.classList.toggle('opened');
        }


        // Functions for altering Table Layout
        const revertTableLayout = (selectedAction) => {
            switch (selectedAction) {
                case 'search' :
                    toggleSearch();
                    break;
                default :
                    hideAllCheckboxes();
            }
        }

        const changeTableLayout = (e, action) => {
            let selectedAction = action; // store selected action in case it changes to 'revert'
            action = (e.currentTarget.classList.contains('is-active')) ? 'revert' : action;
    
            switch (action) {
                case 'delete' :
                    activateTopMenuButton(e.currentTarget);
                    showCheckboxes();
                    activateFormActionButton(action);
                    break;
                case 'search' :
                    toggleSearch();
                    break;
                case 'import' : break;
                case 'export' : break;
                case 'revert' :
                    deactivateTopMenuButton(e.currentTarget);
                    revertTableLayout(selectedAction);
                    deactivateFormActionButtons();
                    break;
            }
        }


        // Register Event Listeners for Top Right Menu buttons if they exist
        if (document.querySelector('.top-right-delete') != null) {
            document.querySelector('.top-right-delete').addEventListener('click', (e) => changeTableLayout(e, 'delete'));
        }
        if (document.querySelector('.top-right-search') != null) {
            document.querySelector('.top-right-search').addEventListener('click', (e) => changeTableLayout(e, 'search'));
        }
    }

    const deleteSelectedEntries = (e) => {
        e.preventDefault();

        // Get form corresponding to delete action
        let form = document.getElementById('delete-form');
        // Get checked checkboxes
        let checkboxes = document.querySelectorAll('input[form="delete-form"]:checked');

        // Add checkboxes to delete form
        checkboxes.forEach(checkbox => {
            checkbox.style.height = '0';
            checkbox.style.position = 'absolute';
            checkbox.style.opacity = '0';
            form.appendChild(checkbox);
        });

        // Submit form if any checkboxers were checked
        if (checkboxes.length >= 1) {
            form.submit();
        }
    }

    // Initialization goes here
    if (document.querySelector('.has-links') != null) {
        initializeClickableRows();
    }
    
    if (document.querySelector('#select-all') != null) {
        initializeSelectAll();
    }
    
    if (document.querySelector('.scroll-table.is-editable') != null) {
        initializeTopRightMenu();
        initializeSearchFields();

        // Form action buttons for table editing
        // if (document.getElementById('form-action-delete-button') != null) {
        //     document.getElementById('form-action-delete-button')
        //     .addEventListener('click', deleteSelectedEntries);
        // }
    }
}

window.addEventListener('DOMContentLoaded', initializeTableFunctions);