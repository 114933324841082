//"use strict";

window.generateFileList = function (dropzone) {
    
    var files = dropzone.getAcceptedFiles();
    var list = [];
    jQuery.each(files, function(index, file) {
        list.push({
            filename: file.name,
            upload_path: JSON.parse(file.xhr.response).path
        });
    });
    return (list.length <= 0 ? null : JSON.stringify(list));
};

jQuery(document).ready(function() {
    const triggerScrollEvent = () => {
        window.dispatchEvent(new Event('scroll'));
    }

    const initialize = () => {
        // Required for readjustment of bottomized side menu on page resize
        document.querySelector('body').addEventListener('click', triggerScrollEvent); 

        //Only add event listener, if there are collapsible elements
        if (document.querySelector('.collapser') != null) {
            document.addEventListener("click", collapseOrExpandItem);
        }

        //only execute if page contains a filter element
        if (document.querySelector('.filter') != null) {
            initializeFilter();
        }

        //only execute if page contains a notifications tile
        if (document.querySelector('.notification-tile') != null) {
            initializeNotificationTile();
        }

        //only execute if page contains at least 1 table for permission previews and its required #role selectbox
        if (document.querySelector('.permission-preview-container') != null && document.querySelector('#role') != null) {
            initializePermissionPreviewTables();
        }

        //only execute if page contains elements that trigger slide down animations
        if (document.querySelector('.scroll-trigger') != null) {
            initializeScrollTrigger();
        }
    };

    // Function for expanding/collapsing tiles if clicked on
    const collapseOrExpandItem = (e) => {
        let target = e.target;
        while ( target != null) {

            // Check if clicked item was a collapser
            if (target.classList.contains('collapser')) {

                // Look for the element containing the collapser that was clicked on
                let elementToChange = Array.from(document.querySelectorAll('.collapsible'))
                                        .find(element => element.contains(target) === true);
                elementToChange.classList.toggle('collapsed');

                // Stop cycling through ancestors of target after the correct one was found
                break;
            }
            target = target.parentElement;
        }
        
    };

    // Function for setting up filter buttons for messages eg. inside damagereport view.
    const initializeFilter = () => {
        // Set default state to is-active
        document.querySelectorAll('.filter .button').forEach(button => button.classList.add('is-active'));

        const setMessageDisplay = (messageContainer, clickedButton, displaySetting) => {
            messageContainer.querySelectorAll('.data-entry').forEach(message => {
                let messageClasses = Array.from(message.classList);
                let buttonClasses = Array.from(clickedButton.classList);
                let classFound = messageClasses.some(className => buttonClasses.includes(className));
                if (classFound) {
                    message.style.display = displaySetting;
                }
            });
        };
        document.querySelectorAll('.filter .button')
            .forEach(filter => filter.addEventListener('click', (e) => {
                let clickedButton = e.currentTarget;
                let messageContainer = e.currentTarget.parentElement.parentElement.nextSibling; // selects next sibling of filter

                //Skip #text elements some browsers generate
                while(messageContainer.nodeName.toUpperCase() != 'DIV') {
                    messageContainer = messageContainer.nextSibling;
                }

                // Hide messages if clicked button was already active
                if (clickedButton.classList.contains('is-active')) {
                    setMessageDisplay(messageContainer, clickedButton, 'none');
                    clickedButton.classList.remove('is-active');
                }

                // Display messages if clicked button was inactive
                else {
                    setMessageDisplay(messageContainer, clickedButton, 'flex');
                    clickedButton.classList.add('is-active');
                };
        }));
    }

    // Function for closing notifications with success/failure messages ect.
    const initializeNotificationTile = () => {
        const closeNotifications = (e) => {
            let clickedElement = e.target;
            
            while (!clickedElement.matches('.delete')) {
                if (clickedElement === e.currentTarget) {
                    return;
                }
                clickedElement = clickedElement.parentElement;
            }

            e.currentTarget.style.display = 'none';
        }
        document.querySelector('.notification-tile').addEventListener('click', closeNotifications);
    }

    // Function for showing/hiding a table with role permissions
    const initializePermissionPreviewTables = () => {
        const previewTables = Array.from(document.querySelectorAll('.permission-preview-container .permission-table'));

        $('#role').change(function() {
            let selectedValue = $('#role').val();
            let tableToActivate = previewTables.find(table => table.id.includes(selectedValue));
            
            previewTables.forEach(table => table.style.display = 'none');

            // Return if Selection is cleared
            if (selectedValue === '') {
                return;
            }

            tableToActivate.style.display = 'table';
        });
    }

    // Function for showing/hiding some elements via a separate scroll trigger
    const initializeScrollTrigger = () => {
        const scrollActivators = document.querySelectorAll('.scroll-trigger');

        const animateScroll = (element) => {
            if (document.querySelector('.is_stuck')) {
                document.querySelector('.is_stuck').style.position = 'fixed';
            }

            j(element).slideDown(500);
        }

        const setupScrollEventFor = (element) => {
            let event = element.dataset.scrollEvent;
            let scrollTarget = document.querySelector(element.dataset.scrollTargetSelector);
            
            switch (event) {
                case 'focus' : j(element).focus( () => animateScroll(scrollTarget) ); break;
                case 'change' : j(element).change( () => animateScroll(scrollTarget) ); break;
                default : console.log('no viable event trigger given');
            }
        }
        
        scrollActivators.forEach(element => setupScrollEventFor(element));
    }
    
    // Initialization goes here
    initialize();
});