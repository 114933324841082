/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./libraries');

/**
 * Then we load our own javascript files that are able to use the libraries
 * loaded above.
 */

require('./design');
require('./chartBuilder');
require('./popups');
require('./intro');
require('./sidemenu');
require('./dynamic_width_elements');
require('./tables');
require('./form/general');
require('./form/phone_numbers');
require('./form/float_inputs');
require('./form/create_contract');
require('./form/at_select');
require('./dropdown_cards');
require('./toggle_all_checkboxes');
require('./print_svg.js');
require('./tabs.js');